import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../firebase/datacontext.js';

import Top from '../elements/top.js';

const Pacientes = () => {
  
    const { getPacientes, pacientes } = useContext(DataContext);

    //USE NAVIGATE
    const navigate = useNavigate();

    //Evitar error de imagen
    function clearErrIm(x){
        var x = document.getElementById(x);
        x.style.display = "none";
    }

    //RECARGAR
    function reloadPage(){
        window.location.reload();
    }

    //USE EFFECT
    useEffect(() => {
        getPacientes();
     },[]);
  
    return (
        <div className="content">
            <Top/>

            <div className="mainBox">
                <div className="topMain">
                    <h1>PACIENTES</h1>
                    <div className="topBtns">
                        <button className="btn" onClick={() => reloadPage()}>RECARGAR</button>
                        <button className="btn" onClick={() => navigate('/CrearPaciente')}>DAR DE ALTA PACIENTE</button>
                    </div>
                </div>

                {
                    pacientes.length > 0
                    ?
                    <div className="list">
                        {
                        pacientes.map((item, index) => (
                            <Link className='listBox' to={`/Paciente/${ item.id }`}  key={item.id}>
                                <div className='listBoxSec1'>
                                    <div className="listBox_im">
                                        <img id={"proim" + item.id}  src={'https://campuzano.renderplayz.com/server/perfiles/' + item.id + '_perfil.jpg'} alt="Foto perfil" onError={() => clearErrIm("proim" + item.id)}/> 
                                    </div>
                                    <div className="listBox_data">
                                        <p className="listBox_main">{item.nombre + ' ' + item.apellido}</p>
                                        <p className="listBox_sec">{item.mail}</p>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                    :
                    <p className="loadingMsg">Aún no existen usuarios</p>
                }

               
            </div>
        </div>
  
    );
  }
  
  export default Pacientes;