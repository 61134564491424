import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataContext } from '../firebase/datacontext.js';
import axios from 'axios';
import Top from '../elements/top.js';

const Paciente = () => {
  
    const { getPaciente, paciente, sumarPuntos, canjearPuntos, asignarPromo } = useContext(DataContext);

    const [puntossum, setPuntossum] = useState(0);
    const [puntosres, setPuntosres] = useState(0);


    //EXTRAER ID
    var u = window.location.pathname;
    var l = u.substr(10);
    console.log(l);

    //
    const [foto, setFoto] = useState('https://campuzano.renderplayz.com/server/perfiles/' + l + '_perfil.jpg');

    //
    function clearErrIm(){
        var x = document.getElementById('profile_image');
        x.style.display = "none";
    }

    //OBTENER PROMO ACTIVA
    const [promoactive, setPromoactive] = useState([]);

    async function getPromoactive(a) {
        try {
            const response = await axios.post('https://campuzano.renderplayz.com/server/llamar_promo.php', {
                id: a
            });
            console.log(response.data);
            setPromoactive(response.data);
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //USE EFFECT
    useEffect(() => {
        getPaciente(l);
    },[]);

    //USE EFFECT
    useEffect(() => {
       if(paciente.length > 0){
            getPromoactive(paciente[0].promo_activa)
       }else{
           console.log("Error al cargar paciente")
       }
        
     },[paciente]);


  
  
    return (
        <div className="content">

            <Top/>

            <div className="topMain">
                <h1>PERFIL DEL PACIENTE/CLIENTE</h1>
                <div className="topBtns">
                       
                </div>
            </div>

            <div className="perfilBox">
                <div className="perfilBtns">
                    <Link className="perfilLink" to={'/EditarPaciente'}>EDITAR</Link>
                    <Link className="perfilLink" to={'/RegistroFotos'}>VER FOTOS</Link>
                </div>

                {
                    paciente.length > 0
                    ?
                    <div className="perfilData">
                        <div className="perfilTop">
                            <div className="perfilBox_im">
                                <img id="profile_image" src={foto} alt="Foto perfil" onError={clearErrIm}/>
                            </div>
                            <div className="perfilBox_name">
                                <p className="perfilBox_nameT">{paciente[0].nombre + ' ' + paciente[0].apellido}</p>
                                <p className="perfilBox_nameG">{paciente[0].genero}</p>
                            </div>
                        </div>
                        
                        <div className="perfilMid">
                            <div className="perfilMid_box">
                                <p className="perfilMid_t">Correo:</p>
                                <p className="perfilMid_s">{paciente[0].mail}</p>
                            </div>
                            <div className="perfilMid_box">
                                <p className="perfilMid_t">Dirección:</p>
                                <p className="perfilMid_s">{paciente[0].direccion}</p>
                            </div>
                            <div className="perfilMid_box">
                                <p className="perfilMid_t">Teléfono:</p>
                                <p className="perfilMid_s">{paciente[0].telefono}</p>
                            </div>
                            <div className="perfilMid_box">
                                <p className="perfilMid_t">Fecha de nacimiento:</p>
                                <p className="perfilMid_s">{paciente[0].fecha_nacimiento}</p>
                            </div>
                            <div className="perfilMid_box">
                                <p className="perfilMid_t">Puntos:</p>
                                <p className="perfilMid_s">{paciente[0].puntos}</p>
                            </div>
                        </div>

                        <div>
                            <p className="perfilMid_t">Promo activa</p>
                            <div>
                                {
                                    promoactive.length > 0
                                    ?
                                    <div>
                                         <p className="perfilMid_s">Promo {promoactive[0].nombre}</p>
                                         <button className="btnmin4" onClick={() => asignarPromo(paciente[0].id, 0)}>Entregar promo</button>
                                    </div>
                                    :
                                    <p className="perfilMid_s">Sin promos activas</p>
                                }
                            </div>
                        </div>

                        <div className="perfilPointsarea">
                            <div className="perfilPoints">
                                <input
                                    type="number"
                                    id="puntossum"
                                    name="puntossum"
                                    value={puntossum}
                                    onChange={e => setPuntossum(e.target.value)}
                                />
                                <button className="btnmin2" onClick={() => sumarPuntos(paciente[0].id, puntossum)}>AÑADIR PUNTOS</button>
                            </div>
                            <div className="perfilPoints">
                                <input
                                    type="number"
                                    id="puntosres"
                                    name="puntosres"
                                    value={puntosres}
                                    onChange={e => setPuntosres(e.target.value)}
                                />
                                <button className="btnmin2" onClick={() => canjearPuntos(paciente[0].id, puntosres)}>CANJEAR PUNTOS</button>
                            </div>
                        </div>
                    </div>
                    :
                    <p className="loadingMsg">Cargando...</p>
                }
            </div>
           
        </div>
  
    );
  }
  
  export default Paciente;