import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo2.png';

const Top = () => {

  
  
    return (
        <div className="top">
        
            <div className="menuLogo">
                <img src={logo} alt="logo"/>
            </div>

            <div className="menuTop">
                <Link className="menuLink" to="/Pacientes">PACIENTES</Link>
                <Link className="menuLink" to="/Productos">PRODUCTOS</Link>
                <Link className="menuLink" to="/Promos">PROMOCIONES</Link>
                <Link className="menuLink" to="/Cafeteria">CAFETERÍA</Link>
                <Link className="menuLink" to="/Skincare">SKINCARE</Link>
            </div>

        </div>
  
    );
  }
  
  export default Top;