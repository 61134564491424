import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../firebase/datacontext.js';
import logo from '../images/logo2.png';

const Login = () => {
    //USE NAVIGATE
    const navigate = useNavigate();

    const { logIn } = useContext(DataContext);
  
    //
    const [mail, setMail] = useState();
    const [pass, setPass] = useState();

    //Entrar test
    function enterTest(){
      if(mail == "campuzano" && pass == "campuzano"){
        navigate('/Pacientes');
      }else{
        alert("Credenciales equivocadas");
      }
    }
  
    return (
      <div className="content">
  
        <div className="loginBox">

          <div className="loginTop">
            <img src={logo} alt="logo"/>
            <h1>LOGIN</h1>
          </div>
  
          
          <div className="loginFields">
            <div className="loginField">
              <label htmlFor="email">USUARIO:</label>
              <input
                type="text"
                id="email"
                name="email"
                onChange={e => setMail(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="lastname">PASSWORD:</label>
              <input
                type="password"
                id="password"
                name="password"
                onChange={e => setPass(e.target.value)}
              />
            </div>
          </div>
          
          <button className="btnBig" onClick={enterTest}>LOGIN</button>
        </div>
      
      </div>
  
    );
  }
  
  export default Login;