import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { DataContext } from '../firebase/datacontext.js';

const SubirFotos = () => {
  
    const { paciente, subirFoto } = useContext(DataContext);

    //
    const [image, setImage] = useState(null)
    console.log(paciente);

    //
    function selectImage( event ){
        const imageFiles = event.target.files[0];
        setImage(imageFiles);
        console.log(imageFiles);
    }
  
  
    return (
        <div>
           
            <div className="file-uploader">
                <p>TEST</p>
                <input type="file" onChange={selectImage}/>
                <button onClick={() => subirFoto(paciente[0].id, image)}>SUBIR</button>
            </div>
               
           
        </div>
  
    );
  }
  
  export default SubirFotos;