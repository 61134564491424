import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../firebase/datacontext.js';

import Top from '../elements/top.js';
import { formToJSON } from 'axios';

const Productos = () => {
  
    const { getProductos, productos } = useContext(DataContext);

    //USE NAVIGATE
    const navigate = useNavigate();

    //Evitar error de imagen
    function clearErrIm(x){
        var x = document.getElementById(x);
        x.style.display = "none";
    }

    //RECARGAR
    function reloadPage(){
        window.location.reload();
    }

    //USE EFFECT
    useEffect(() => {
        getProductos();
     },[]);
  
    return (
        <div className="content">
            <Top/>

            <div className="mainBox">
                <div className="topMain">
                    <h1>PRODUCTOS</h1>
                    <div className="topBtns">
                        <button className="btn" onClick={() => reloadPage()}>RECARGAR</button>
                        <button className="btn" onClick={() => navigate('/CrearProducto')}>DAR DE ALTA PRODUCTO</button>
                    </div>
                </div>

                {
                    productos.length > 0
                    ?
                    <div className="list">
                        {
                        productos.map((item, index) => (
                            <Link className='listBox' to={`/Producto/${ item.id }`}  key={item.id}>
                                <div className='listBoxSec1'>
                                    <div className="listBox_im">
                                        <img id={"proim" + item.id}  src={'https://campuzano.renderplayz.com/server/' + item.foto} alt="Foto del producto" onError={() => clearErrIm("proim" + item.id)}/> 
                                    </div>
                                    <div className="listBox_data">
                                        <p className="listBox_main">{item.nombre}</p>
                                        <p className="listBox_sec">{item.descripcion}</p>
                                        <p className="listBox_sec"><strong>Tipo:</strong> {item.tipo}</p>
                                        <p className="listBox_sec"><strong>Precio:</strong> {item.precio}</p>
                                        <p className="listBox_sec"><strong>Puntos que otorga:</strong> {item.puntos}</p>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                    :
                    <p className="loadingMsg">Aún no existen productos</p>
                }

               
            </div>
        </div>
  
    );
  }
  
  export default Productos;