import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebaseConfig';
import { doc, addDoc, setDoc, collection, getDocs, getDoc, updateDoc, deleteDoc, arrayUnion, arrayRemove, query, onSnapshot, orderBy, limit, where, deleteField, startAfter } from "firebase/firestore";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, deleteUser, signOut } from "firebase/auth";
import axios from 'axios';

export const DataContext = createContext();

export const DataProvider = ({children}) => {

    //USE NAVIGATE
    const navigate = useNavigate();

    //CHECAR CONEXIO BD
    function test(){
        axios.get('https://campuzano.renderplayz.com/server/connect.php')
        .then(respuesta => console.log(respuesta.data))
        .catch(error => console.log(error));
    }

    //REGISTRAR USUARIO
    async function registrar(mail, pass, name, lastname, address, tel, birthdate, gender){
        try{
            const user = await createUserWithEmailAndPassword(auth, mail, pass); 
            console.log(user);
            try {
                const response = await axios.post('https://campuzano.renderplayz.com/server/crear_paciente.php',{
                    id: auth.currentUser.uid,
                    mail: mail,
                    nombre: name,
                    apellido: lastname,
                    cliente: '',
                    direccion: address,
                    telefono: tel,
                    fecha_nacimiento: birthdate,
                    genero: gender,
                    foto_perfil: '',
                    puntos: 0
                })
                console.log(response.data);
                logOut();
                navigate('/Pacientes');

            } catch (error) {
                console.log(error);
                deleteUser(auth.currentUser);
            }
            
        }catch(error){
            console.log(error);
            alert('Error al registrar al paciente' + '\n' + error)
        }
    }

    //LOG IN
    async function logIn(mail, pass){
        try{
            const user = await signInWithEmailAndPassword(auth, mail, pass);
            console.log("registro");
            navigate('/Wall');
            window.location.reload();
        }catch(error){
            console.log(error);
            alert("Wrong email or password.")
        }
    }


    //LOGOUT USER
    function logOut(){
        signOut(auth).then(() => {
        console.log("Sesión terminada")
        }).catch((error) => {
        console.log(error);
        });
    }

    //OBTENER PACIENTES
    const [pacientes, setPacientes] = useState([]);
    const [activePage, setActivepage] = useState(1);
    
    var limit_page = 4;

    async function getPacientes() {
        try {
            //const response = await axios.get(`https://campuzano.renderplayz.com/server/call2.php?page=${activePage}&limit=${limit_page}`);
            const response = await axios.get('https://campuzano.renderplayz.com/server/llamar_pacientes.php?');
            console.log(response.data);
            setPacientes(response.data);
            //setActivepage(activePage + 1);
            console.log(activePage);
        } catch (error) {
            console.log(error);
        }
    }

    //OBTENER PACIENTE
    const [paciente, setPaciente] = useState([]);


    async function getPaciente(a) {
        try {
            const response = await axios.post('https://campuzano.renderplayz.com/server/llamar_paciente.php', {
                id: a
            });
            console.log(response.data);
            setPaciente(response.data);
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //EDITAR PACIENTE
    async function editarPaciente(a, b, c, d, e, f, g, h, i) {
        try {
            const response = await axios.put('https://campuzano.renderplayz.com/server/editar_paciente.php', {
                id: a,
                mail: b,
                nombre: c,
                apellido: d,
                cliente: e,
                telefono: f,
                direccion: g,
                fecha_nacimiento: h,
                genero: i,
                foto_perfil: ''
            });
            console.log(response.data);
            navigate('/Pacientes');
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //PRODUCTOS
    const [productos, setProductos] = useState([]);
    
    async function getProductos() {
        try {
            const response = await axios.get('https://campuzano.renderplayz.com/server/llamar_productos.php?');
            console.log(response.data);
            setProductos(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    //REGISTRAR PRODUCTOS
    async function registrarProducto(name, type, desc, price, points, pic){
        try {
            const formData = new FormData();
            formData.append('nombre', name);
            formData.append('tipo', type);
            formData.append('descripcion', desc);
            formData.append('precio', price);
            formData.append('puntos', points);
            formData.append('foto', pic);

            const response = await axios.post('https://campuzano.renderplayz.com/server/crear_producto.php', formData,{
                headers: {
                    'Content-Type': 'multipart/form-data', // Asegura que se envía como form data
                },
            })
            console.log(response.data);
            navigate('/Productos');

        } catch (error) {
            console.log(error);
        }      
    }

    //OBTENER PRODUCTO
    const [producto, setProducto] = useState([]);

    async function getProducto(a) {
        try {
            const response = await axios.post('https://campuzano.renderplayz.com/server/llamar_producto.php', {
                id: a
            });
            console.log(response.data);
            setProducto(response.data);
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //EDITAR PRODUCTO
    async function editarProducto(id, name, type, desc, price, points, pic){
        try {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('nombre', name);
            formData.append('tipo', type);
            formData.append('descripcion', desc);
            formData.append('precio', price);
            formData.append('puntos', points);
            formData.append('foto', pic);

            const response = await axios.post('https://campuzano.renderplayz.com/server/editar_producto.php', formData,{
                headers: {
                    'Content-Type': 'multipart/form-data', // Asegura que se envía como form data
                },
            })
            console.log(response.data);
            navigate('/Productos');

        } catch (error) {
            console.log(error);
        }      
    }

    //CAFETERIA
    const [cafeteria, setCafeteria] = useState([]);
    
    async function getCafeteria() {
        try {
            const response = await axios.get('https://campuzano.renderplayz.com/server/llamar_cafeteria.php');
            console.log(response.data);
            setCafeteria(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    //ASIGNAR ENTREGA CAFETERIA
    async function asignarEntrega(a, b) {
        try {
            const response = await axios.put('https://campuzano.renderplayz.com/server/asignar_entrega.php', {
                id: a,
                entrega: b,
            });
            console.log(response.data);
            window.location.reload();
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //SKINCARE
    const [skincare, setSkincare] = useState([]);
    
    async function getSkincare() {
        try {
            const response = await axios.get('https://campuzano.renderplayz.com/server/llamar_skincare.php');
            console.log(response.data);
            setSkincare(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    //PROMOS
    const [promos, setPromos] = useState([]);
    
    async function getPromos() {
        try {
            const response = await axios.get('https://campuzano.renderplayz.com/server/llamar_promos.php?');
            console.log(response.data);
            setPromos(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    //OBTENER PROMO
    const [promo, setPromo] = useState([]);

    async function getPromo(a) {
        try {
            const response = await axios.post('https://campuzano.renderplayz.com/server/llamar_promo.php', {
                id: a
            });
            console.log(response.data);
            setPromo(response.data);
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //EDITAR PROMO
    async function editarPromo(id, name, desc, price, points, pic){
        try {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('nombre', name);
            formData.append('descripcion', desc);
            formData.append('precio', price);
            formData.append('puntos', points);
            formData.append('foto', pic);

            const response = await axios.post('https://campuzano.renderplayz.com/server/editar_promo.php', formData,{
                headers: {
                    'Content-Type': 'multipart/form-data', // Asegura que se envía como form data
                },
            })
            console.log(response.data);
            navigate('/Promos');

        } catch (error) {
            console.log(error);
        }      
    }

    //REGISTRAR PROMOS
    async function registrarPromo(name, desc, price, points, pic){
        try {
            const formData = new FormData();
            formData.append('nombre', name);
            formData.append('descripcion', desc);
            formData.append('precio', price);
            formData.append('puntos', points);
            formData.append('foto', pic);

            const response = await axios.post('https://campuzano.renderplayz.com/server/crear_promo.php', formData,{
                headers: {
                    'Content-Type': 'multipart/form-data', // Asegura que se envía como form data
                },
            })
            console.log(response.data);
            navigate('/Promos');

        } catch (error) {
            console.log(error);
        }      
    }

    //ASIGNAR ENTREGA DE PROMO
    async function asignarPromo(a, b) {
        try {
            const response = await axios.put('https://campuzano.renderplayz.com/server/asignar_promo.php', {
                id: a,
                promo_activa: b,
            });
            console.log(response.data);
            window.location.reload();
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }


    //SUBIR FOTOS
    async function subirFoto(a, b, c){
        var x = document.getElementById(c);

        if (b != null){
            try {
                const formData = new FormData();
                formData.append('paciente_id', a);
                formData.append('foto', b);
    
                const response = await axios.post('https://campuzano.renderplayz.com/server/subir_foto.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Asegura que se envía como form data
                    },
                });
                console.log(response.data);
                x.style.display = "none";
                getFotos(a);
    
            } catch (error) {
                console.log(error);
            }
        }else{
            alert("Elige una imagen");
        } 
    }

    //ELIMINAR FOTO
    async function eliminarFoto(a, b) {
        try {
            const response = await axios.post('https://campuzano.renderplayz.com/server/eliminar_foto.php', {
                id: b
            });
            console.log(response.data);
            getFotos(a);
        } catch (error) {
            console.error(error);
        }
    }

    //CARGAR FOTOS
    const [fotos, setFotos] = useState([]);
    const [fotosPage, setFotosPage] = useState(1);

    async function getFotos(a) {
        try {
            const response = await axios.get(`https://campuzano.renderplayz.com/server/cargar_fotos2.php?id=${a}`);
            console.log(response.data);
            setFotos(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    //SUMAR PUNTOS AL USUARIO
    async function sumarPuntos(a, b){
        try {
             
            const response = await axios.put('https://campuzano.renderplayz.com/server/puntos_sumar.php', {
                id: a,
                puntos: b,
            });
            console.log(response);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }

    //CANJEAR PUNTOS AL USUARIO
    async function canjearPuntos(a, b){
        try {
             
            const response = await axios.put('https://campuzano.renderplayz.com/server/puntos_canjear.php', {
                id: a,
                puntos: b,
            });
            console.log(response);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }

    //TEST STRIPE
    async function getStripe() {
        try {
            const response = await axios.get('https://campuzano.renderplayz.com/server/clientes_stripe.php');
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    //USE EFFECT
    useEffect(() => {
       
     },[]);
    
    


    return(
        <DataContext.Provider 
            value={{ 
                registrar, 
                logIn, 
                logOut,
                getPacientes, 
                pacientes, 
                getPaciente, 
                paciente, 
                editarPaciente,
                producto,
                getProducto,
                productos,
                getProductos,
                registrarProducto,
                editarProducto,
                promo,
                getPromo,
                promos,
                getPromos,
                registrarPromo,
                editarPromo,
                asignarPromo,
                cafeteria,
                getCafeteria,
                asignarEntrega,
                skincare,
                getSkincare,
                subirFoto, 
                getFotos, 
                eliminarFoto, 
                fotos, 
                getStripe,
                sumarPuntos,
                canjearPuntos
            }}>
            
            {children}
        </DataContext.Provider>
    )
}