import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataContext } from '../firebase/datacontext.js';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Top from '../elements/top.js';

const RegistroFotos = () => {
  
    const { paciente, getFotos, fotos, subirFoto, eliminarFoto } = useContext(DataContext);

    const [image, setImage] = useState(null);

    //
    function selectImage( event ){
        const imageFiles = event.target.files[0];
        setImage(imageFiles);
        console.log(imageFiles);
    }

    //
    function openPop(){
        document.getElementById('popup1').style.display = "flex";
    }

    //
    function closePop(){
        document.getElementById('popup1').style.display = "none";
    }

   //USE EFFECT
    useEffect(() => {
       getFotos(paciente[0].id);
    },[]);

    return (
        <div className="content">

            <div className="popup" id="popup1">
                <div className="popbox">
                    <div className="closepop" onClick={closePop}>&times;</div>
                    <h3>Sube una fotografía</h3>
                    <div className="popfile">
                        <input type="file" onChange={selectImage}/>
                    </div>
                   
                    <button className="btnBig2" onClick={() => subirFoto(paciente[0].id, image, "popup1")}>SUBIR</button>
                </div>
            </div>

            <Top/>
            
            <div className="fotosBox">
                <div className="topMain">
                    <h1>REGISTRO FOTOGRÁFICO</h1>
                    <h2>{paciente[0].nombre + ' ' + paciente[0].apellido}</h2>
                    <div className="topBtns">
                        <button className="btn" onClick={openPop}>AÑADIR FOTO</button>
                    </div>
                </div>
                {
                    fotos.length != 0
                    ?
                    <Carousel dynamicHeight={true}>
                    {fotos.map((item, index) => (
                        <div key={item.id} className="fotoBox">
                           
                            <img src={'https://campuzano.renderplayz.com/server/' + item.foto} alt="foto"/>
                          
                            <div className="fotoBox_data">
                                <p className="perfilMid_t">Fecha de carga:</p>
                                <p className="perfilMid_s">{item.fecha_carga}</p>
                                <button className="btnmin" onClick={() => eliminarFoto(paciente[0].id, parseInt(item.id))}>Eliminar</button>
                            </div>
                        </div>
                        ))}
                    </Carousel>
                    :
                    <p className="loadingMsg">Sin fotos. Añade fotografías a este perfil.</p>
                }
                
            </div>

        </div>
  
    );
  }
  
  export default RegistroFotos;