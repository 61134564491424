import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { DataContext } from '../firebase/datacontext.js';

import Top from '../elements/top.js';

const CrearPromo = () => {
  
    const { registrarPromo } = useContext(DataContext);
  
    //
    const [nombre, setNombre] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [precio, setPrecio] = useState(0);
    const [puntos, setPuntos] = useState(0);
    const [foto, setFoto] = useState(null);

    //
    function selectImage( event ){
      const imageFiles = event.target.files[0];
      setFoto(imageFiles);
      console.log(imageFiles);
    }
    
    return (
      <div className="content">
        <Top/>
  
        <div className="registerBox">
          <h1>REGISTRAR PROMO</h1>
          <div className="registerFields">
            
            <div className="loginField">
              <label htmlFor="nombre">NOMBRE DE LA PROMOCIÓN:</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                onChange={e => setNombre(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="nombre">DESCRIPCIÓN:</label>
              <input
                type="text"
                id="descripcion"
                name="descripcion"
                onChange={e => setDescripcion(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="nombre">PRECIO:</label>
              <input
                type="number"
                id="precio"
                name="precio"
                onChange={e => setPrecio(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="nombre">VALOR EN PUNTOS:</label>
              <input
                type="number"
                id="puntos"
                name="puntos"
                onChange={e => setPuntos(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="nombre">IMAGEN:</label>
              <input type="file" onChange={selectImage}/>
            </div>


          </div>
          
          <button className="btnBig2" onClick={() => registrarPromo(nombre, descripcion, precio, puntos, foto)}>REGISTRAR</button>
        </div>
      
      </div>
  
    );
  }
  
  export default CrearPromo;