import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../firebase/datacontext.js';

import Top from '../elements/top.js';
import { formToJSON } from 'axios';

const Skincare = () => {
  
    const { getSkincare, skincare } = useContext(DataContext);

    //USE NAVIGATE
    const navigate = useNavigate();

    //Evitar error de imagen
    function clearErrIm(x){
        var x = document.getElementById(x);
        x.style.display = "none";
    }

    //RECARGAR
    function reloadPage(){
        window.location.reload();
    }

    //USE EFFECT
    useEffect(() => {
        getSkincare();
     },[]);
  
    return (
        <div className="content">
            <Top/>

            <div className="mainBox">
                <div className="topMain">
                    <h1>PEDIDOS SKINCARE</h1>
                    
                </div>

                {
                   skincare.length > 0
                    ?
                    <div className="list">
                        {
                        skincare.map((item, index) => (
                            <div className='listBox2' key={item.id}>
                                <div className='listBoxSec1'>
                                    <div className="listBox_im">
                                        <img id={"proim" + item.id}  src={'https://campuzano.renderplayz.com/server/' + item.foto} alt="Foto del producto" onError={() => clearErrIm("proim" + item.id)}/> 
                                    </div>
                                    <div className="listBox_data">
                                        <p className="listBox_main">{item.producto}</p>
                                        <p className="listBox_sec">{item.descripcion}</p>
                                        <p className="listBox_sec"><strong>Status:</strong> {item.entrega}</p>
                                        <p className="listBox_sec"><strong>Comprador:</strong> {item.comprador_nombre +  ' ' + item.comprador_apellido}</p>
                                        <p className="listBox_sec"><strong>Dirección de entrega:</strong> {item.direccion}</p>
                                        <button className="btnmin3">ENTREGA</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    <p className="loadingMsg">Aún no existen productos</p>
                }

               
            </div>
        </div>
  
    );
  }
  
  export default Skincare;