import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataContext } from '../firebase/datacontext.js';

import Top from '../elements/top.js';

const Producto = () => {
  
    const { getProducto, producto } = useContext(DataContext);


    //EXTRAER ID
    var u = window.location.pathname;
    var l = u.substr(10);
    console.log(l);

    //
    function clearErrIm(){
        var x = document.getElementById('profile_image');
        x.style.display = "none";
    }

    //USE EFFECT
    useEffect(() => {
        getProducto(l);
     },[]);
  
  
    return (
        <div className="content">

            <Top/>

            <div className="topMain">
                <h1>PRODUCTO</h1>
                <div className="topBtns">
                       
                </div>
            </div>

            <div className="perfilBox">
                <div className="perfilBtns">
                    <Link className="perfilLink" to={'/EditarProducto'}>EDITAR</Link>
                </div>

                {
                    producto.length > 0
                    ?
                    <div className="perfilData">
                        <div className="perfilTop">
                            <div className="perfilBox_im">
                                <img id="profile_image" src={'https://campuzano.renderplayz.com/server/' + producto[0].foto} alt="Foto" onError={clearErrIm}/>
                            </div>
                            <div className="perfilBox_name">
                                <p className="perfilBox_nameT">{producto[0].nombre}</p>
                                <p className="perfilBox_nameG">{producto[0].tipo}</p>
                            </div>
                        </div>
                        
                        <div className="perfilMid">
                            <div className="perfilMid_box">
                                <p className="perfilMid_t">Descripción:</p>
                                <p className="perfilMid_s">{producto[0].descripcion}</p>
                            </div>
                        </div>
                    </div>
                    :
                    <p className="loadingMsg">Cargando...</p>
                }
            </div>
           
        </div>
  
    );
  }
  
  export default Producto;