import React from 'react';
import {Routes, Route } from 'react-router-dom';
import { DataProvider } from './firebase/datacontext.js';

import Login from './pages/login.js';
import CrearPaciente from './pages/crearpaciente.js';
import Pacientes from './pages/pacientes.js';
import Paciente from './pages/paciente.js';
import EditarPaciente from './pages/editarpaciente.js';
import SubirFotos from './pages/subirfotos.js';
import RegistroFotos from './pages/registrofotos.js';
import Productos from './pages/productos.js';
import Producto from './pages/producto.js';
import CrearProducto from './pages/crearproducto.js';
import EditarProducto from './pages/editarproducto.js';
import Promos from './pages/promos.js';
import Promo from './pages/promo.js';
import CrearPromo from './pages/crearpromo.js';
import EditarPromo from './pages/editarpromo.js';
import Cafeteria from './pages/cafeteria.js';
import Skincare from './pages/skincare.js';

import './styles.css';


function App() {
  return (
    
    <DataProvider>
        <Routes>  
          <Route path="/" element={<Login/>}/>
          <Route path="/CrearPaciente" element={<CrearPaciente/>}/>
          <Route path="/Pacientes" element={<Pacientes/>}/>
          <Route path="Paciente/:id" element={<Paciente/>}/>
          <Route path="/EditarPaciente" element={<EditarPaciente/>}/>
          <Route path="/SubirFotos" element={<SubirFotos/>}/>
          <Route path="/RegistroFotos" element={<RegistroFotos/>}/>
          <Route path="/Productos" element={<Productos/>}/>
          <Route path="/Producto/:id" element={<Producto/>}/>
          <Route path="/CrearProducto" element={<CrearProducto/>}/>
          <Route path="/EditarProducto" element={<EditarProducto/>}/>
          <Route path="/Promos" element={<Promos/>}/>
          <Route path="/Promo/:id" element={<Promo/>}/>
          <Route path="/CrearPromo" element={<CrearPromo/>}/>
          <Route path="/EditarPromo" element={<EditarPromo/>}/>
          <Route path="/Cafeteria" element={<Cafeteria/>}/>
          <Route path="/Skincare" element={<Skincare/>}/>
        </Routes>
    </DataProvider>

    
  );
}

export default App;