import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataContext } from '../firebase/datacontext.js';

import Top from '../elements/top.js';

const EditarPaciente = () => {
  
    const { paciente, editarPaciente } = useContext(DataContext);
    
    const [name, setName] = useState(paciente[0].nombre) 
    const [lastname, setLastname] = useState(paciente[0].apellido);
    const [tel, setTel] = useState(paciente[0].telefono);
    const [address, setAddress] = useState(paciente[0].direccion);
    const [birthdate, setBirthdate] = useState(paciente[0].fecha_nacimiento);
    const [gender, setGender] = useState(paciente[0].genero);
    const [puntos, setPuntos] = useState(paciente[0].puntos);

    //
    console.log(paciente)
  
  
    return (
        <div className="content">
        <Top/>
  
        <div className="registerBox">
          <h1>EDITAR PACIENTE</h1>
          <div className="registerFields">
            
            <div className="loginField">
              <label htmlFor="name">NOMBRE(S):</label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="lastname">APELLIDO(S):</label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                value={lastname}
                onChange={e => setLastname(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="tel">TELÉFONO:</label>
              <input
                type="text"
                id="tel"
                name="tel"
                value={tel}
                onChange={e => setTel(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="address">DIRECCIÓN:</label>
              <input
                type="text"
                id="address"
                name="address"
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="birthdate">FECHA DE NACIMIENTO:</label>
              <input
                type="date"
                id="birthdate"
                name="birthdate"
                value={birthdate}
                onChange={e => setBirthdate(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="gender">GÉNERO:</label>
              <select name="gender" id="gender" value={gender} onChange={e => setGender(e.target.value)}>
                <option value="">Seleccionar</option>
                <option value="Femenino">Femenino</option>
                <option value="Masculino">Masculino</option>
              </select>
            </div>
            <div className="loginField">
              <label htmlFor="puntos">REASIGNAR PUNTOS:</label>
              <input
                type="number"
                id="puntos"
                name="puntos"
                value={puntos}
                onChange={e => setPuntos(e.target.value)}
              />
            </div>
          </div>
          
          <button className="btnBig2" onClick={() => editarPaciente( paciente[0].id, paciente[0].mail, name, lastname, paciente[0].cliente, tel, address, birthdate, gender, puntos)}>GUARDAR</button>
        </div>
      
      </div>
  
    );
  }
  
  export default EditarPaciente;