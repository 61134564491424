import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { DataContext } from '../firebase/datacontext.js';

import Top from '../elements/top.js';

const CrearPaciente = () => {
  
    const { registrar, test } = useContext(DataContext);
  
    //
    const [mail, setMail] = useState('');
    const [pass, setPass] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [tel, setTel] = useState('');
    const [address, setAddress] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [gender, setGender] = useState('');

    return (
      <div className="content">
        <Top/>
  
        <div className="registerBox">
          <h1>REGISTRAR PACIENTE</h1>
          <div className="registerFields">
            <div className="loginField">
              <label htmlFor="email">EMAIL:</label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={e => setMail(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="pass">ASIGNAR PASSWORD:</label>
              <input
                type="password"
                id="pass"
                name="pass"
                onChange={e => setPass(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="name">NOMBRE(S):</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="lastname">APELLIDO(S):</label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                onChange={e => setLastname(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="tel">TELÉFONO:</label>
              <input
                type="text"
                id="tel"
                name="tel"
                onChange={e => setTel(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="address">DIRECCIÓN:</label>
              <input
                type="text"
                id="address"
                name="address"
                onChange={e => setAddress(e.target.value)}
              />
            </div>
            <div className="loginField">
              <label htmlFor="birthdate">FECHA DE NACIMIENTO:</label>
              <input
                type="date"
                id="birthdate"
                name="birthdate"
                onChange={e => setBirthdate(e.target.value)}
              />
            </div>
            <div className="loginField">
            <label htmlFor="gender">GÉNERO:</label>
              <select name="gender" id="gender" onChange={e => setGender(e.target.value)}>
                <option value="">Seleccionar</option>
                <option value="Femenino">Femenino</option>
                <option value="Masculino">Masculino</option>
              </select>
            </div>
          </div>
          
          <button className="btnBig2" onClick={() => registrar(mail, pass, name, lastname, address, tel, birthdate, gender)}>REGISTRAR</button>
        </div>
      
      </div>
  
    );
  }
  
  export default CrearPaciente;